<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="10" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                      <ValidationProvider name="Lab" vid="laboratory_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="laboratory_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('irriWaterTest.lab')}}<span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="sendSample.laboratory_id"
                            :options="laboratoryList"
                            id="laboratory_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Note" vid="note">
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="note"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                              {{$t('complain.note')}}
                          </template>
                          <b-form-textarea
                            id="note"
                            v-model="sendSample.note"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Note (bn)" vid="note_bn">
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="note_bn"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                              {{$t('complain.note_bn')}}
                          </template>
                          <b-form-textarea
                            id="note_bn"
                            v-model="sendSample.note_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-send')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { irrgationWaterTestingSampleSendToLab } from '../../api/routes'

export default {
  props: ['waterTestId', 'item'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.$t('globalTrans.save'),
      sendSample: {
        laboratory_id: 0,
        note: '',
        note_bn: ''
      },
      laboratoryList: [],
      userList: [],
      user: [],
      complainRequestList: []
    }
  },
  computed: {
    organizationtList: function () {
      const listObject = this.$store.state.commonObj.organizationProfileList
      return listObject
    }
  },
  watch: {
  },
  created () {
    this.getLaboratories(this.item.org_id)
  },
  mounted () {
    core.index()
  },
  methods: {
    getLaboratories (orgId) {
      this.loading = true
      RestApi.getData(irriSchemeServiceBaseUrl, 'laboratory/list-all', null).then(response => {
        if (response.success) {
          const laboratoryData = response.data.filter(lab => lab.org_id === orgId)
          this.laboratoryList = laboratoryData.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.id, text: obj.laboratory_name_bn }
            } else {
              return { value: obj.id, text: obj.laboratory_name }
            }
          })
        }
        this.loading = false
      })
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      this.sendSample.id = this.complainRequestList.id
      this.sendSample.sender_id = this.user.user_id
      result = await RestApi.putData(irriSchemeServiceBaseUrl, irrgationWaterTestingSampleSendToLab + this.waterTestId, this.sendSample)
      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-send')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
