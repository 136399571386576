<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irriWaterTest.industrial_water_request') }}</h4>
      </template>
      <template v-slot:body>
      <b-row>
        <b-col xl="6" lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro.organization')"
            label-for="org_id"
            >
            <b-form-select
              plain
              v-model="search.org_id"
              :options="organizationList"
              id="org_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col xl="6" lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_division.division')"
            label-for="division_id"
            >
            <b-form-select
              plain
              v-model="search.far_division_id"
              :options="divisionList"
              id="division_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>

        <b-col xl="6" lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_district.district')"
            label-for="district_id"
            >
            <b-form-select
              plain
              v-model="search.far_district_id"
              :options="districtList"
              id="district_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col xl="6" lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_upazilla.upazilla')"
            label-for="upazilla_id"
            >
            <b-form-select
              plain
              v-model="search.far_upazilla_id"
              :options="upazilaList"
              id="upazilla_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>

        <b-col xl="6" lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_union.union')"
            label-for="union_id"
            >
            <b-form-select
              plain
              v-model="search.far_union_id"
              :options="unionList"
              id="union_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col xl="6" lg="6" md="6" sm="12" xs="12">
          <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
        </b-col>
      </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irriWaterTest.industrial_water_request_list') }}</h4>
          </template>
          <template v-slot:headerAction>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(sample_number)="data">
                      <span>{{ $n(data.item.sample_number, { useGrouping: false }) }}</span>
                    </template>
                    <template v-slot:cell(created_at)="data">
                      <span>{{ data.item.created_at | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(updated_at)="data">
                      <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFormat}}</span>
                    </template>
                    <template v-slot:cell(view)="data">
                      <b-button class="btn btn-success" @click="pdfExport(data.item)">{{ $t('globalTrans.pdf') }}</b-button>
                    </template>
                    <template v-slot:cell(payment_status)="data">
                      <span v-if="data.item.payment !== null" class="capitalize">{{ data.item.payment.pay_status }}</span>
                    </template>
                    <template v-slot:cell(payment_status_bn)="data">
                      <span v-if="data.item.payment !== null">{{ data.item.payment.pay_status === 'success' ? $t('globalTrans.success') : $t('globalTrans.pending') }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-detail @click="details(data.item)"><i class="fas fa-eye"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Send" v-if="data.item.status !== 3 && data.item.status !== 4  && data.item.status !== 5 && data.item.status !== 6" v-b-modal.modal-send @click="sendData(data.item)"><i class="fas fa-paper-plane" aria-hidden="true"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Reject" v-if="data.item.status !== 3 && data.item.status !== 4  && data.item.status !== 5 && data.item.status !== 6" v-b-modal.modal-reject @click="rejectData(data.item)"><i class="fas fa-times"></i></a>
                      <b-button v-if="data.item.nothi_status == 1 && $store.state.Auth.authUser.ssoLogin" style="font-size: 12px !important; margin-top:10px" variant="btn btn-xs btn-success" size="sm" @click="finalSave(data.item)" ><i class="far fa-check-square m-0"></i> {{$t('elearning_venue.forward_to_nothi')}}</b-button>
                      <b-button v-if="data.item.nothi_status == 2 && $store.state.Auth.authUser.ssoLogin" style="font-size: 12px !important; margin-top:10px" variant="btn btn-xs btn-success" size="sm" @click="finalSaveResend(data.item)" ><i class="far fa-check-square m-0"></i> {{$t('elearning_venue.forward_to_nothi')}}</b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-send" size="lg" :title="$t('irriWaterTest.send_sample')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <SendModal :waterTestId="editItemId" :item="item"/>
    </b-modal>
    <b-modal id="modal-reject" size="lg" :title="$t('irriWaterTest.reject')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <RejectModal :waterTestId="editItemId"/>
    </b-modal>
    <b-modal id="modal-detail" size="lg" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <b-button @click="pdfExport(item)" class="ml-4 btn-success water-test-pdf-button">
          {{  $t('globalTrans.print') }}
        </b-button>
        <DetailModal :item="item" :id="editItemId" :key="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>
import DetailModal from '../drinking-water-request/DetailModal'
import SendModal from './SendModal'
import RejectModal from './RejectModal'
import { industrialWaterTestingList, waterTestingRequestToggleStatus, waterTestingRequestProcessing, pendingNothiIndustrialWaterTestingList, reSentNothiIndustrialWaterTestingList } from '../../api/routes'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import ExportPdf from '../export_pdf_water_testing_details'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import Store from '@/store'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    DetailModal, SendModal, RejectModal
  },
  data () {
    return {
      search: {
        org_id: '0',
        far_division_id: '0',
        far_district_id: '0',
        far_upazilla_id: '0',
        far_union_id: '0',
        status: '0',
        farmer_id: '',
        testing_type_id: '0'
      },
      item: '',
      rows: [],
      disabled: false,
      projectList: [],
      districtList: [],
      upazilaList: [],
      unionList: []
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    currentLocale () {
      return this.$i18n.locale
    },
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    testingTypeList: function () {
      const ObjectData = this.$store.state.commonObj.testingTypeList
      return ObjectData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.name_bn }
          } else {
            return { value: obj.id, text: obj.name }
          }
        })
    },
    waterTestingStatusList: function () {
      const ObjectData = this.$store.state.commonObj.waterTestingStatusList
      return ObjectData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.name_bn }
          } else {
            return { value: obj.id, text: obj.name }
          }
        })
    },
    detailTitle () {
       return this.$t('farmerOperator.water_testing_request_detail')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('externalUserIrrigation.application_id'), class: 'text-center' },
          { label: this.$t('farmerOperator.applicant_name'), class: 'text-center' },
          { label: this.$t('globalTrans.application_date'), class: 'text-center' },
          { label: this.$t('farmerOperator.sample_number'), class: 'text-center' },
          { label: this.$t('globalTrans.view_application'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.payment_status'), class: 'text-center' },
          { label: this.$t('pump_install.created_date'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name_bn' },
          { key: 'created_at' },
          { key: 'sample_number' },
          { key: 'view' },
          { key: 'testing_status_name_bn' },
          { key: 'payment_status_bn' },
          { key: 'updated_at' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name' },
          { key: 'created_at' },
          { key: 'sample_number' },
          { key: 'view' },
          { key: 'testing_status_name' },
          { key: 'payment_status' },
          { key: 'updated_at' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'search.far_division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.far_district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.far_upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    },
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (this.search.far_division_id) {
              this.districtList = this.getDistrictList()
            }
            if (this.search.far_district_id) {
              this.upazilaList = this.getUpazilaList()
            }
            if (this.search.far_upazilla_id) {
              this.unionList = this.getUnionList()
            }
        }
    }
  },
  mounted () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        far_division_id: this.authUser.office_detail.division_id,
        far_district_id: this.authUser.office_detail.district_id,
        far_upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
    this.loadData()
  },
  methods: {
    // ==========eNothi Start===================
    finalSave (item) {
        this.toggleStatusCustom2(irriSchemeServiceBaseUrl, pendingNothiIndustrialWaterTestingList, item)
      },
      finalSaveResend (item) {
        this.toggleStatusCustom2(irriSchemeServiceBaseUrl, reSentNothiIndustrialWaterTestingList, item)
      },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('elearning_venue.are_you_sure_forward_to_nothi'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        // RestApi.getData(baseUrl, uri, item).then(response => {
        RestApi.postData(baseUrl, uri, item).then(response => {
          if (response.success) {
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
    // ==========eNothi End=====================
    details (item) {
      this.editItemId = item.id
      this.item = item
      if (item.status === 1) {
        RestApi.putData(irriSchemeServiceBaseUrl, `${waterTestingRequestProcessing}/${item.id}`).then(response => {
          if (response.success) {
            this.loadData()
          }
        })
      }
    },
    pdfExport222 (data) {
      const rowData = this.getPdfData(data)
      const extraData = {
        totalHeaderRows: 0
      }
      const reportTitle = this.$i18n.locale === 'en' ? 'Water Testing Request Report' : 'পানি পরীক্ষার অনুরোধের প্রতিবেদন'
      // const columnWids = ['*', '*', '*', '*']
      ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', data.org_id, reportTitle, rowData, null, extraData)
    },
    getPdfData (data) {
      if (this.$i18n.locale === 'bn') {
        const rowData = [
          [
            { text: this.$t('complain.organization') }, { text: data.org_name_bn },
            { text: this.$t('farmerOperator.applicant_name') }, { text: data.name_bn }
          ],
          [
            { text: this.$t('complain.division') }, { text: data.division_name_bn },
            { text: this.$t('complain.district') }, { text: data.district_name_bn }
          ],
          [
            { text: this.$t('complain.upazila') }, { text: data.upazilla_name_bn },
            { text: this.$t('complain.union') }, { text: data.union_name_bn }
          ],
          [
            { text: this.$t('farmerOperator.village') }, { text: data.far_village_bn },
            { text: this.$t('globalTrans.from_date') }, { text: this.$d(new Date(data.from_date)) }
          ],
          [
            { text: this.$t('farmerOperator.sample_number') }, { text: this.$n(data.sample_number, { useGrouping: false }) },
            { text: this.$t('globalTrans.to_date') }, { text: this.$d(new Date(data.to_date)) }
          ],
          [
            { text: this.$t('farmerOperator.testing_type') }, { text: data.testing_type_name_bn },
            { text: this.$t('farmerOperator.sample_serial') }, { text: this.$n(data.sample_serial, { useGrouping: false }) }
          ],
          [
            { text: this.$t('globalTrans.status') }, { text: data.testing_status_name_bn },
            { text: this.$t('externalUserIrrigation.application_id') }, { text: data.application_id }
          ]
        ]
        return rowData
      } else {
        const rowData = [
          [
            { text: this.$t('complain.organization') }, { text: data.org_name },
            { text: this.$t('farmerOperator.applicant_name') }, { text: data.name }
          ],
          [
            { text: this.$t('complain.division') }, { text: data.division_name },
            { text: this.$t('complain.district') }, { text: data.district_name }
          ],
          [
            { text: this.$t('complain.upazila') }, { text: data.upazilla_name },
            { text: this.$t('complain.union') }, { text: data.union_name }
          ],
          [
            { text: this.$t('farmerOperator.village') }, { text: data.far_village },
            { text: this.$t('globalTrans.from_date') }, { text: this.$d(new Date(data.from_date)) }
          ],
          [
            { text: this.$t('farmerOperator.sample_number') }, { text: this.$n(data.sample_number, { useGrouping: false }) },
            { text: this.$t('globalTrans.to_date') }, { text: this.$d(new Date(data.to_date)) }
          ],
          [
            { text: this.$t('farmerOperator.testing_type') }, { text: data.testing_type_name },
            { text: this.$t('farmerOperator.sample_serial') }, { text: this.$n(data.sample_serial, { useGrouping: false }) }
          ],
          [
            { text: this.$t('globalTrans.status') }, { text: data.testing_status_name },
            { text: this.$t('externalUserIrrigation.application_id') }, { text: data.application_id }
          ]
        ]
        return rowData
      }
    },
    sendData (item) {
      this.editItemId = item.id
      this.item = item
    },
    rejectData (item) {
      this.editItemId = item.id
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id,
          far_division_id: this.authUser.office_detail.division_id,
          far_district_id: this.authUser.office_detail.district_id,
          far_upazilla_id: this.authUser.office_detail.upazilla_id
        })
      }
      this.loadData()
    },
    remove (item) {
      this.changeStatus(irriSchemeServiceBaseUrl, waterTestingRequestToggleStatus, item, 'common', 'industrialWaterTestingList')
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      await RestApi.getData(irriSchemeServiceBaseUrl, industrialWaterTestingList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response.data))
          this.paginationData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const organizationList = this.$store.state.orgList
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unionList = this.$store.state.commonObj.unionList
      const testingTypeList = this.$store.state.commonObj.testingTypeList
      const waterTestingStatusList = this.$store.state.commonObj.waterTestingStatusList
      return data.data.map(item => {
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        let divisionObject = { text: '', text_bn: '' }
        let districtObject = { text: '', text_bn: '' }
        let upazilaObject = { text: '', text_bn: '' }
        let unionObject = { text: '', text_bn: '' }
        if (item.far_division_id) {
          divisionObject = divisionList.find(division => division.value === item.far_division_id)
          districtObject = districtList.find(district => district.value === item.far_district_id)
          upazilaObject = upazilaList.find(upazilla => upazilla.value === item.far_upazilla_id)
          unionObject = unionList.find(union => union.value === item.far_union_id)
        }
        const testingTypeObject = testingTypeList.find(testingType => testingType.id === item.testing_type_id)
        const statusListObject = waterTestingStatusList.find(testingStatus => testingStatus.id === item.status)
        const orgData = {
          org_name: orgObject.text_en,
          org_name_bn: orgObject.text_bn
        }
        const divisionData = {
          division_name: divisionObject.text,
          division_name_bn: divisionObject.text_bn
        }
        const districtData = {
          district_name: districtObject.text,
          district_name_bn: districtObject.text_bn
        }
        const upazillaData = {
          upazilla_name: upazilaObject.text,
          upazilla_name_bn: upazilaObject.text_bn
        }
        const unionData = {
          union_name: unionObject.text,
          union_name_bn: unionObject.text_bn
        }
        const TestingTypeData = {
          testing_type_name: testingTypeObject.name,
          testing_type_name_bn: testingTypeObject.name_bn
        }
        const waterTestingData = {
          testing_status_name: statusListObject.name,
          testing_status_name_bn: statusListObject.name_bn
        }
        return Object.assign({}, item, orgData, divisionData, districtData, upazillaData, unionData, TestingTypeData, waterTestingData)
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    pdfExport () {
      const reportTitle = this.$t('irriWaterTest.water_testing_request_details')
      ExportPdf.exportPdfDetails(reportTitle, this,
        this.item
      )
    }
  }
}
</script>
